
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat-typography-config(
  $font-family: brandon-grotesque
);
@include mat-core($custom-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tresorvino-primary: mat-palette($mat-indigo);
$tresorvino-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tresorvino-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$tresorvino-theme: mat-light-theme($tresorvino-primary, $tresorvino-accent, $tresorvino-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($tresorvino-theme);

.cdk-overlay-container {
  z-index: 99999;
  .mat-dialog-container {
    padding: 0;
    border-radius: 24px;
    min-width: 450px;
    .card {
      .card-header {
        background-color: #DDD8E6;
        color: #4F2A57;
        display: flex;
        align-items: center;
        padding: 15px 25px;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
        border-bottom: none;
        .close-icon {
          padding: 4px;
          border-radius: 50%;
          background-color: #FFFFFF;
          color: #707070;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: absolute;
          right: 20px;
        }
      }
      .card-body {
        background-color: #F2EFF7;
        padding: 15px 25px;
        max-height: 400px;
        overflow-y: auto;
      }
      .card-footer {
        background-color: #F2EFF7;
        padding: 15px 25px;
        border: none;
      }
    }
  }
}
.tr-main-wrapper, .cdk-overlay-container {
  .mat-icon-button:not(mat-paginator-navigation-*) {
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .mat-form-field:not(.mat-paginator-page-size-select) {
    width: 100%;
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        background-color: transparent;
        .mat-form-field-infix {
          padding: 3px 0 9px 0;
          .mat-input-element {
            color: #181818;
          }
          .mat-select-trigger {
            height: 24px;
            .mat-select-value-text {
              line-height: normal;
            }
          }
        }
      }
      .mat-form-field-underline {
        .mat-form-field-ripple {
          // border-radius: 0 0 12px 12px;
          width: calc(100% - 12px);
          margin: auto;
          right: 0;
        }
        &::before {
          width: calc(100% - 14px);
          margin: 0 auto;
          left: 0;
          right: 0;
          // border-radius: 0 0 12px 12px;
        }
      }
      .mat-form-field-subscript-wrapper {
        // padding: 0;
        margin-top: 5px;
      }
    }
    &.mat-focused {
      .mat-form-field-wrapper {
        .mat-form-field-underline {
          .mat-form-field-ripple {
            background-color: #202A34;
          }
        }
      }
      .mat-form-field-flex {
        .mat-form-field-label {
          color: #202A34;
        }
      }
    }
    &.mat-form-field-disabled {
      opacity: 0.5;
      // .mat-form-field-flex {
      //   background-color: #c0c0c0;
      // }
    }
    &.mat-form-field-type-mat-select {
      .mat-form-field-flex {
        .mat-form-field-infix {
          padding-bottom: 4px;
        }
      }
    }
    &.white-field {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-select-value {
              color: #fff;
            }
            .mat-select-arrow {
              color: #fff;
            }
            .mat-input-element {
              color: #fff;
            }
            .mat-form-field-label {
              color: #fff;
            }
          }
        }
        .mat-form-field-underline {
          background-color: #fff;
          &::before {
            background-color: #fff;
          }
          .mat-form-field-ripple {
            background-color: #fff;
          }
        }
      }
    }
    &.mat-form-field-disabled {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          background-color: rgba(0, 0, 0, 0.07);
          .mat-input-element {
            color: rgba(0, 0, 0, 0.5);
            &:disabled {
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        .mat-form-field-underline {
          .mat-form-field-ripple {
            background-color: #f44336;
          }
        }
      }
    }
  }
  .mat-checkbox {
    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: #482250;
      }
    }
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: #482250;
  }
  .mat-radio-group {
    .mat-radio-button {
      .mat-radio-label {
        margin-bottom: 0;
        .mat-radio-container {
          border-color: #CE4265;
        }
        .mat-radio-inner-circle {
          background-color: #CE4265;
        }
      }
      &.mat-radio-checked {
        .mat-radio-label {
          .mat-radio-outer-circle {
            border-color: #CE4265;
          }
        }
      }
    }
  }
  .public-page-container {
    .mat-form-field {
      .mat-form-field-wrapper {
        .mat-form-field-underline {
          .mat-form-field-ripple {
            border-radius: 0;
            width: 100%;
          }
          &::before {
            width: 100%;
            border-radius: 0;
          }
        }
      }
    }
  }
  .mat-select-panel-wrap {
    .mat-select-panel {
      background-color: #381A3E;
      .mat-option {
        .mat-option-text {
          color: #fff;
        }
      }
      &.white-field {
        background-color: #fff;
        .mat-option {
          .mat-option-text {
            color: #381A3E;
          }
        }
      }
    }
  }
  .mat-progress-bar {
    height: 5px;
    .mat-progress-bar-fill {
      transition: transform 3500ms ease;
      &::after {
        background-color: #CE4265;
      }
    }
  }
}

.mat-menu-panel {
  &.action-items-wrapper {
    padding: 8px 15px;
    box-shadow: 0px 6px 12px #00000029;
    min-height: auto;
    .mat-menu-content {
      padding: 0 !important;
      .mat-menu-item {
        height: 35px;
        line-height: 35px;
        border-bottom: 1px solid #5e5e5e;
        padding: 0;
        &:last-child {
          border-bottom: none;
        }
        .mat-icon {
          font-size: 20px;
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (min-height: 951px) {
  .cdk-overlay-container {
    .mat-dialog-container {
      .card {
        .card-body {
          max-height: 890px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-height: 950px) {
  .cdk-overlay-container {
    .mat-dialog-container {
      .card {
        .card-body {
          max-height: 740px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-height: 800px) {
  .cdk-overlay-container {
    .mat-dialog-container {
      .card {
        .card-body {
          max-height: 590px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-height: 650px) {
  .cdk-overlay-container {
    .mat-dialog-container {
      .card {
        .card-body {
          max-height: 500px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-height: 540px) {
  .cdk-overlay-container {
    .mat-dialog-container {
      .card {
        .card-body {
          max-height: 410px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .cdk-overlay-container .mat-dialog-container {
    min-width: 275px;
  }
}