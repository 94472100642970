@import url("https://use.typekit.net/pky0azz.css");

html, body{
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #FFFFFF;
  color: #381A3E;
}

.public-theme {
  color: #fff;
}

/* Buttons */
.tr-btn-primary {
  background-color: #CE4265;
  color: #FFFFFF;
  border-radius: 25px;
  padding: 5px 15px;
}
.tr-btn-primary:hover,
.tr-btn-primary:focus {
  background-color: #CE4265;
  color: #FFFFFF;
}

.tr-btn-secondary,
.tr-btn-secondary:hover,
.tr-btn-secondary:focus {
  background-color: #E1DFE5;
  /* color: #FFFFFF; */ /* Commented following line for TRES-583*/
  border-radius: 25px;
  padding: 5px 15px;
}
.tr-btn-third,
.tr-btn-third:hover,
.tr-btn-third:focus {
  background-color: transparent;
  border-radius: var(--borderRadius);
  border: 1px solid var(--primaryColor);
  color: var(--thirdBtnColor);
}
.btn:focus {
  box-shadow: none;
}
/* Buttons */

/* Common style */
.tr-shadow {
  box-shadow: 0px 6px 12px #00000029;
}
.font-bold {
  /* font-weight: bold; */
  font-weight: 700;
  font-style: normal;
}
.font-italic {
  /* font-style: italic; */
  font-weight: 400;
  font-style: italic;
}
.table-action {
  text-align: center;
  width: 90px;
}
.tr-border {
  border: 1px solid var(--borderColor)
}
a:not([href]), a:not([href]):hover {
  color: #fff;
}
.disable-action {
  opacity: 0.6;
  pointer-events: none;
}
.full-width {
  margin-left: -15px;
  margin-right: -15px;
}
.br-0 {
  border-radius: 0;
}
.b-0 {
  border: none;
}
.content-center {
  justify-content: center;
}
/* Common style */

/* Table */
.tr-table {
  width: 100%;
}
.tr-table thead th {
  padding: 12px 18px;
  background-color: #482250;
  color: #fff;
  border-right: 1px solid #5E3567;
}
.tr-table thead th:first-child {
  border-top-left-radius: 12px;
}
.tr-table thead th:last-child {
  border-top-right-radius: 12px;
  border-right: none;
}
.tr-table tbody tr {
  background-color: #F2EFF7;
  color: #381A3E;
}
.tr-table tbody tr:last-child td {
  border-bottom: none;
}
.tr-table tbody tr td {
  padding: 12px 18px;
  border-right: 1px solid #DDD8E6;
  border-bottom: 1px solid #DDD8E6;
}
.tr-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}
.tr-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
  border-right: none;
}
/* Table */

/* Modal */
.tr-modal {
  opacity: 1;
  display: block;
}
.tr-modal .modal-content {
  background-color: #fff;
  border-radius: 24px;
  border: none;
}
.tr-modal .modal-header {
  background-color: #DDD8E6;
  color: #4F2A57;
  display: flex;
  align-items: center;
  padding: 15px 52px 15px 25px;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  border-bottom: none;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.tr-modal .modal-header .close-icon {
  padding: 4px;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #707070;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
}
.tr-modal .modal-header .close {
  padding: 4px;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #707070;
  cursor: pointer;
  position: absolute;
  right: 20px;
  width: 32px;
  height: 32px;
  margin: 0;
  opacity: 1;
}
.tr-modal .modal-header .close:hover {
  background-color: #fff;
  color: #707070;
  opacity: 1;
}
.tr-modal .modal-header .close:focus {
  outline: none;
}
.tr-modal .modal-footer {
  border-top: 1px solid var(--borderColor);
  padding: 12px 16px;
}
/* Modal */

/* Form Elements */
input.form-control, input.form-control:focus,
select.form-control, select.form-control:focus,
textarea.form-control, textarea.form-control:focus {
  background-color: #fff;
  border: none;
  /* color: var(--primaryColor); */
  border-radius: 25px;
  box-shadow: none;
}
.form-control:disabled, .form-control[readonly] {
  /* background-color: var(--inputBg); */
  opacity: 0.5;
}
/* Form Elements */

/* Pagination */
.tr-pagination {
  z-index: 9;
  display: inline-block;
}
.tr-pagination ul.pagination > li.page-item > a {
  background-color: transparent;
  color: var(--primaryColor);
  border-color: var(--borderColor);
}
.tr-pagination ul.pagination > li.page-item.first-item > a {
  border-radius: 10px 0px 0px 10px;
}
.tr-pagination ul.pagination > li.page-item.last-item > a {
  border-radius: 0px 10px 10px 0px;
}
.tr-pagination ul.pagination > li.page-item.disabled {
  opacity: 0.6;
}
.tr-pagination ul.pagination > li.page-item.number-item > a {
  border: none;
  padding: 8px 16px;
}
.tr-pagination ul.pagination > li.page-item.number-item.active > a {
  color: var(--primaryBtnBg);
  font-weight: bold;
}
.tr-pagination ul.pagination > li.page-item:not(.disabled) {
  cursor: pointer;
}
/* Pagination */
.form-group {
  margin-bottom: 0;
}
.form-group input[required].form-control{
  -webkit-transition: border-top-color 0.5s linear 0.15s, border-right-color 0.4s linear 0.25s, border-bottom-color 0.5s linear 0.40s, border-left-color 0.5s linear 0.50s;
  transition: border-top-color 0.5s linear 0.15s, border-right-color 0.4s linear 0.25s, border-bottom-color 0.5s linear 0.40s, border-left-color 0.5s linear 0.50s;
}
.form-group input.form-control.ng-touched.ng-invalid:not(.no-error),
.form-group select.form-control.ng-touched.ng-invalid:not(.no-error) {
  border: 1px solid;
  border-top-color: red;
  border-right-color: red;
  border-bottom-color: red;
  border-left-color: red;
}
.error-msg {
  color: red;
  font-size: 12px;
}

.form-group select.form-control{
  text-transform: capitalize;
}

.backdrop{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
  z-index: 1;
}
.allFormBox {
  border-radius: 42px;
  background-color: #4F2A57;
}

.tr-shadow-one {
  box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.26);
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.26);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.26);
}
.action-item-menu {
  position: absolute;
  right: 2px;
  top: 8px;
  cursor: pointer;
  width: 25px;
  text-align: center;
}
.action-item-menu i {
  font-size: 18px;
  padding: 4px 8px;
}
.action-items-wrapper ul li a {
  cursor: pointer;
  padding: 5px 0;
  color: inherit;
}
.action-items-wrapper .action-items>li {
  border-bottom: 1px solid var(--borderColor);
}
.action-items-wrapper .action-items>li:last-child {
  border: none;
}
.tr-modal-wrapper {
  position: relative;
  z-index: 999;
}

/* Tabs */
.tr-tabs-container {
  overflow-y: hidden;
  overflow-x: auto;
  width: calc(100% - 5px);
  padding-bottom: 5px;
}

.tr-tabs-container::-webkit-scrollbar {
  height: 5px;
}
.tr-tabs {
  border: none;
  min-width: 575px;
}
.tr-tabs.nav-tabs .nav-link {
  background-color: #E4DCF0;
  color: #381A3E;
  border: none;
  font-weight: bold;
  border-radius: 12px 12px 0 0;
  padding: 8px 20px;
}
.tr-tabs.nav-tabs .nav-item.show .nav-link, .tr-tabs.nav-tabs .nav-link.active {
  background-color: #F2EFF7;
  color: #381A3E;
}
.tab-content {
  padding: 20px 15px;
  background-color: #F2EFF7;
  border-radius: 0 24px 24px;
}
.tab-content .tab-pane {
  display: none;
}
.tab-content .tab-pane.active {
  display: block;
}
/* Tabs */

.tr-tooltip:hover::before {
  display: inline-block;
}
/* Tooltip */

/* Custom Scroll */
/* width */
::-webkit-scrollbar {
  width: 15px;
}
.scroll-sm ::-webkit-scrollbar {
  width: 7px;
}
.scroll-md ::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray; 
  border-radius: 2px;
  background-color: #fff;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #482250; 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #381A3E; 
}
/* Custom Scroll */

.community-post-wrapper
  .post-comment-section
    .comment-input
      .mat-form-field-wrapper {
        padding-bottom: 0;
}

.community-post-wrapper
  .post-comment-section
    .comment-input
      .mat-form-field-wrapper
        .mat-form-field-underline {
          bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .card-header, .card-body {
    padding: 5px;
  }
  .tr-modal .modal-body {
    max-height: 350px;
    overflow-y: auto;
  }
  .tooltip-hidden .tooltip {
    display: none !important;
  }
}


input:-webkit-autofill {
  background-color: red;
}

/* info icon style */

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: #eee !important;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color: #eee !important;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #eee !important;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  border-left-color: #eee !important;
}

.tooltip-inner {
  font-size: 12px;
  line-height: 14px;
  padding: 10px;
  background: #eee;
  color: #000;
}

.tooltip.show {
  opacity: 1;
}

.dashboard-info-icon {
  cursor: pointer;
  
}

.dashboard-info-icon-orange {
  color: #fff;
}

.dashboard-info-icon-black {
  color: rgb(60, 60, 60);
}

/* Dashboard info icon style end */

.mat-icon.mat-ligature-font[fontIcon]::before {
  font-size: large;
}


/* All from in close button in close icon style */

mat-icon[aria-label="CLose icon"]::before, mat-icon[aria-label="Close icon"]::before {
  position: absolute;
  top: 7.5px;
  right: 7.5px;
} 

/* All from in close button in close icon style end */

/* Disable arrows of input */
/* Chrome, Safari, Edge, Opera */
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-arrows[type=number] {
  -moz-appearance: textfield;
}

/* blur element class */
.blur {
  filter: blur(4px);
}

.offer-lock .tooltip-container {
  transform: translateX(-70%);
}

.modelText {
  height: 70vh;
  overflow-x: auto;
}

.user-name-on-card {
  cursor: pointer;
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}